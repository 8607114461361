.header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100vw;
  /*background: linear-gradient(180deg,rgba(29,33,41,.93) 40%,rgba(29,33,41,.5) 70%,rgba(29,33,41,0));*/
  background-color: $neutrals5;
  backdrop-filter: blur(4px);

  &__right {
    display: flex;
    align-items: center;
    gap: 25px;

    @include media($tabletM) {
      gap: 10px;

      .metamask {
        display: none;
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 40px 0 30px;

    @include media-landscape($laptopS) {
      height: 60px;
      padding: 0 20px;
    }

    @include media($tabletM) {
      height: 60px;
      padding: 0 10px 0 20px;
    }
  }

  &__wrapper > .btn {
    display: none;
    padding: 0;
  }

  &__logo {
    display: flex;

    svg {
      width: 100%;
    }

    @include media-landscape($laptopS) {
      height: 50%;
    }

    @include media($tabletM) {
      width: 37%;
      min-width: 150px;
    }
  }

  &__links {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    gap: 30px;
  }

  &__link {
    font-weight: 600;
    font-size: 18px;
    color: $white;
    text-decoration: none;
    transition: color 0.3s;
  }

  &__burger {
    display: none;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 60px;
    background: transparent;
    border: none;

    @include media-landscape($laptopS) {
      display: flex;

      svg {
        width: 32px;
        height: 32px;
        color: $white;
        cursor: pointer;
      }
    }

    @include media($tabletM) {
      display: flex;

      svg {
        width: 32px;
        height: 32px;
        color: $white;
        cursor: pointer;
      }
    }
  }
}
