/*--------------------
  SORT TABS
--------------------*/
@import "../helpers/utils";


.sort-tabs {
  border-bottom: 1px solid $neutrals4;

  &__btn {
    position: relative;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $neutrals3;
    text-transform: capitalize;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.15s linear;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: $primary1;
      transition: height 0.15s linear;
    }

    &.active {
      color: $primary1;
      pointer-events: none;

      &::before {
        height: 2px;
      }

      &:hover,
      &:focus {
        color: $primary1;
      }
    }

    &:hover,
    &:focus {
      color: $white;
    }
  }
}

