/*--------------------
  CARD NFT
--------------------*/
@import "../helpers/utils";

.card-nft {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 581px;
  padding: 24px;
  border: 1px solid $neutrals4;
  border-radius: 16px;
  transition: border-color 0.15s linear, background-color 0.15s linear;

  &.checked {
    background-color: rgba($primary3, 0.2);
    border-color: $primary1;
  }

  @include media($tabletL) {
    padding: 16px;
  }

  &__info-list {
    margin-bottom: 16px;
  }

  &__info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__info-text {
    margin-right: 8px;
    color: $neutrals2;
  }

  &__preview {
    margin-bottom: 16px;

    transition: opacity 0.15s linear;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__process {
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    &-title {
      margin-right: 8px;
    }
  }

  &__checkbox {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    > * {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  &__name {
    margin-bottom: 16px;

    @include media($tabletL) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &.show-checkbox &__preview {
    cursor: pointer;
  }
}
