/*--------------------
  STATUS
--------------------*/
@import "src/assets/styles/helpers/utils";

.status {
  @include font(10px, 14px, 700);
  display: inline-flex;
  padding: 4px 8px;
  color: $neutrals1;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 4px;

  &--unlocked {
    color: $secondary11;
    background-color: $secondary12;
  }

  &--locked {
    color: $secondary31;
    background-color: $secondary32;
  }
}
