/*--------------------
  PREVIEW
--------------------*/
@import "src/assets/styles/helpers/utils";

.preview {
  display: flex;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;

  .lazy-load-image-background {
    width: 100%;
    border-radius: 16px;
  }

  &__video-wrapper {
    position: relative;
  }

  &__video-placeholder {
    filter: blur(15px);
  }

  video {
    max-width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: cover;
  }

  &__btn-play {
    position: absolute;
    right: 8px;
    bottom: 8px;
    width: 40px;
    height: 40px;
    font-size: 0;
    background-color: $neutrals6;
    border: none;
    border-radius: 100%;
    cursor: pointer;
  }
}
