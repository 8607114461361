/* --------------------
  VARIABLES
-------------------- */

// COLOR
$white: #ffffff;
$black: #000000;
$primary1: #ba62ff;
$primary2: #9e53d9;
$primary3: rgba(#c780ff, 0.2);
$neutrals1: $white;
$neutrals2: #b7b9bf;
$neutrals3: #7a818e;
//$neutrals3: #6c7380;
$neutrals35: #5a5e66;
$neutrals4: #363940;
$neutrals5: #1d2129;
$neutrals6: #15181f;
$secondary1: #dcb04a;
$secondary11: #66ccbb;
$secondary12: rgba(#61baab, 0.2);
$secondary21: #ffcd56;
$secondary22: rgba(#ffcd56, 0.2);
$secondary31: #4d88ff;
$secondary32: rgba(#3377ff, 0.2);
$secondary41: #ff6a4d;
$secondary42: rgba(#ff6a4d, 0.2);

// FONT
$ff-base: "Roboto", "Arial", sans-serif;
$fw-base: 400;
$fs-base: normal;
$fz-base: 14px;
$lh-base: 22px;

// MEDIA
$mobileS: 320px;
$mobileM: 375px;
$mobileL: 425px;
$tabletS: 480px;
$tabletM: 640px;
$tabletL: 768px;
$laptopS: 992px;
$laptopM: 1024px;
$laptopL: 1200px;
$laptopXL: 1440px;
$fullHD: 1920px;

// COMPONENTS STYLES VARIABLES
$container: 1230px;
