/*--------------------
  TEXT
--------------------*/
@import "src/assets/styles/helpers/utils";

.caption {
  @include font(10px, 14px, 700);
  text-transform: uppercase;
  letter-spacing: 1px;
}
