.logo {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100px;
  padding-top: 100px;
  background-color: $neutrals5;

  @include media-landscape($laptopS) {
    translate: -100vw;
    width: 300px;
    padding-top: 60px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    transition: translate 0.5s;

    &_opened {
      overflow-x: hidden;
      overflow-y: auto;
      translate: 0;
    }
  }

  @include media($tabletM) {
    translate: -100vw;
    width: 100vw;
    padding-top: 80px;
    transition: translate 0.5s;

    &_opened {
      overflow-x: hidden;
      overflow-y: auto;
      translate: 0;
    }
  }

  &__profile {
    display: none;
    flex-direction: column;
    width: 90%;
    margin: 0 auto 20px;
    padding: 0 30px 20px 6px;
    font-size: 18px;
    line-height: 60px;
    border-bottom: 1px solid $neutrals4;

    @include media($tabletM) {
      display: flex;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    margin-bottom: 40px;

    @include media-landscape($laptopS) {
      margin-bottom: 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 310px;
    background: $neutrals5;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
    transform: translateX(-100%);
    transition: transform ease 0.3s, box-shadow 0.1s 0s;
    pointer-events: none;

    @include media($mobileL) {
      content: none;
    }
  }
}


.nav {
  width: 100%;
  margin: 0;
  padding: 0;

  @include media-landscape($laptopS) {
    width: 90%;
    margin-inline: auto;
  }

  @include media($tabletM) {
    width: 90%;
    margin-inline: auto;
  }

  &__link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    cursor: pointer;

    @include media-landscape($laptopS) {
      justify-content: flex-start;
      height: 50px;
      padding: 0 10px;
      text-decoration: none;
    }

    @include media($tabletM) {
      justify-content: flex-start;
      height: 60px;
      padding: 0 10px;
      text-decoration: none;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 2;
      display: block;
      width: 60px;
      height: 60px;
      background: #3a3a46;
      border-radius: 50%;
      transform: scale(0);
      transition: transform 0.5s;

      @include media-landscape($laptopS) {
        content: none;
      }

      @include media($tabletM) {
        content: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background: $neutrals5;

      @include media-landscape($laptopS) {
        content: none;
      }

      @include media($tabletM) {
        content: none;
      }
    }
  }

  &__svg {
    position: relative;
    z-index: 3;

    @include media-landscape($laptopS) {
      width: 26px;
    }

    path {
      transition: fill 0.5s;
      fill: $neutrals3;
    }
  }

  &__item {
    &:not(.nav__item_disabled) .nav__link:hover .nav__svg path {
      fill: $white;
    }

    &:not(.nav__item_disabled) .nav__link:hover::before {
      transform: scale(1);
    }

    &:not(.nav__item_disabled) .nav__link:hover .nav__tooltip {
      color: $white;
    }

    .nav {
      width: calc(100% + 210px);
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s;

      .nav__link {
        justify-content: flex-start;
        height: 60px;
        padding-left: 100px;
        text-decoration: none;

        @include media($tabletM) {
          height: 50px;
          padding-left: 62px;
        }

        @include media-landscape($tabletL) {
          height: 45px;
          padding-left: 60px;
        }
      }

      .nav__link::after {
        z-index: 1;
        width: 100px;
      }

      .nav__link::before {
        content: none;
      }

      .nav__tooltip {
        position: static;
        padding-left: 0;
        gap: 16px;
        font-size: 18px;

        @include media-landscape($tabletL) {
          font-size: 16px;
        }
      }
    }

    //.nav__item_disabled .nav__link {
    //	pointer-events: none;
    //}

    &_active {
      .nav__svg path {
        fill: $primary2;
      }

      .nav__tooltip {
        color: $primary2;
      }
    }

    &_disabled {
      position: relative;
      //color: #3a3a46;
      cursor: default;
      //pointer-events: none;

      path {
        fill: $neutrals35;
      }

      .nav__tooltip {
        color: $neutrals35;
      }

      .nav__tooltip::after {
        content: "Coming soon";
        position: absolute;
        right: -50px;
        z-index: 10;
        padding: 5px 10px;
        font-size: 14px;
        color: $neutrals2;
        background-color: $neutrals35;
        border-radius: 8px;
        opacity: 0;
        transition: translate 0.5s, opacity 0.5s;
      }

      &:hover .nav__tooltip::after {
        translate: 60% 0;
        opacity: 1;
      }
    }
  }

  &__tooltip {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100px;
    z-index: 0;
    display: flex;
    align-items: center;
    width: 190px;
    padding: 5px 0;
    font-size: 20px;
    color: $neutrals2;
    transform: translateX(-100%);
    transition: transform 0.3s, color 0.3s;

    @include media-landscape($laptopS) {
      position: static;
      padding-left: 20px;
      font-size: 16px;
      transform: translateX(0);
    }

    @include media($tabletM) {
      position: static;
      width: auto;
      padding-left: 20px;
      transform: translateX(0);
    }
  }
}

.sidebar_opened {
  .nav__item .nav {
    max-height: 200px;
    padding-bottom: 20px;
  }
}

.sidebar:not(.sidebar_opened):hover {
  &::before {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    transform: translateX(0);
    transition: transform ease 0.3s, box-shadow 0s 0.2s;
  }

  .nav__tooltip {
    transform: translateX(0);
  }

  .nav__item .nav {
    max-height: 200px;
    padding-bottom: 20px;
  }
}
