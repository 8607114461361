/*--------------------
  BUTTON
--------------------*/
@import "src/assets/styles/helpers/utils";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $white;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid $white;
  border-radius: 8px;
  cursor: pointer;
  transition: color 0.15s linear, background-color 0.15s linear, border-color 0.15s linear, opacity 0.15s linear;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--primary {
    background-color: $primary1;
    border-color: $primary1;

    &:hover,
    &:focus {
      background-color: $primary2;
      border-color: $primary2;
    }
  }

  &--secondary {
    color: $primary1;
    border-color: $primary1;

    &:hover,
    &:focus {
      color: $primary2;
      border-color: $primary2;
    }
  }

  &--tertiary {
    color: $neutrals2;
    border-color: $neutrals4;

    &:hover,
    &:focus {
      color: $neutrals1;
    }
  }

  &-checkbox {
    justify-content: flex-start;

    &.checked {
      color: $white;
      border-color: $primary1;
    }

    &__text {
      margin-left: 8px;
      line-height: 1;
    }
  }
}
