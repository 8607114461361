/*--------------------
  COIN
--------------------*/
@import "../helpers/utils";

.coin {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 16px;
  }

  &__counter {
    margin-bottom: 2px;
  }

  &__description {
    color: $neutrals2;
  }
}
