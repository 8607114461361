/*--------------------
  ICON
--------------------*/
@import "src/assets/styles/helpers/utils";

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  min-width: 56px;
  height: 56px;
  background-color: $neutrals4;
  border-radius: 16px;

  svg {
    width: 22px;
    height: 22px;
  }

  &--small {
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 8px;
  }

  &--success {
    background-color: $secondary12;

    path {
      stroke: $secondary11;
    }
  }

  &--error {
    background-color: $secondary42;

    path {
      stroke: $secondary41;
    }
  }

  &--unlocked {
    background-color: $primary3;

    path {
      stroke: $primary1;
    }
  }

  &--locked {
    background-color: $secondary22;

    path {
      stroke: $secondary21;
    }
  }
}
