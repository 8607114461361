/*--------------------
  NFTs
--------------------*/
@import "src/assets/styles/helpers/utils";

.nft {
  &__title {
    margin-bottom: 16px;
  }

  &__container {
    margin-top: 24px;

    @include media($tabletL) {
      margin-top: 16px;
    }
  }

  &__counter {
    margin-bottom: 24px;
    font-weight: 600;
    color: $neutrals3;

    @include media($tabletL) {
      margin-bottom: 16px;
    }
  }

  &__search-input {
    position: relative;
    margin-bottom: 24px;

    @include media($tabletL) {
      margin-bottom: 16px;
    }
  }

  &__pagination {
    margin-bottom: 24px;

    @include media($tabletL) {
      margin-bottom: 16px;
    }
  }

  &__sort {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-left: -16px;

    > * {
      &:first-child {
        width: 100%;
      }

      width: calc(50% - 16px);
      margin-bottom: 16px;
      margin-left: 16px;

      @include media($tabletS) {
        width: 100%;
      }
    }
  }

  &__filter {
    &-options {
      margin-bottom: 24px;
    }

    &-buttons {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -12px;
      margin-left: -12px;

      @include media($mobileM) {
        flex-direction: column;
      }
    }
  }

  &__tools {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
    padding-top: 12px;
    background: linear-gradient(180deg, rgba(29, 33, 41, 0.93) 40%, rgba(29, 33, 41, 0.5) 70%, rgba(29, 33, 41, 0));

    @include media($mobileL) {
      top: 44px;
      flex-direction: column;
    }
  }

  &__tools-col {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__selected {
    display: flex;
    align-items: center;
    margin: 0 5px 0 10px;
    line-height: 40px;
    color: $neutrals2;

    span {
      margin-left: 6px;
      color: #ffffff;
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    margin: 0 3px 0 5px;
    line-height: 40px;
    color: $secondary21;
    text-decoration: underline;
    transition: color 0.2s, stroke 0.2s;
    stroke: $secondary21;

    &:hover {
      color: #ffffff;
      cursor: pointer;
      stroke: #ffffff;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //min-height: calc(100vh - 390px);
    margin: 0 0 -16px -16px;
    padding-top: 24px;

    @include media($tabletL) {
      flex-direction: column;
      align-items: center;
      margin: 0 0 -16px;
    }

    &-item {
      width: 100%;
      max-width: 305px;
      margin: 0 0 16px 16px;

      &.selected .card-nft {
        background-color: rgba($primary3, 0.2);
        border-color: $primary1;
      }

      @include media($tabletL) {
        max-width: 289px;
        margin: 0 0 16px;
      }
    }
  }
}
