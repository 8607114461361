/*--------------------
  LOADER
--------------------*/
@import "src/assets/styles/helpers/utils";

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin: 0 auto;
  border: 4px solid $primary1;
  border-top-color: transparent;
  border-radius: 50%;
  animation: infiniteRotate 1s linear infinite;

  @include media($tabletL) {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }
}

.loader-screen {
  position: fixed;
  z-index: 30;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $neutrals6;
}

@keyframes infiniteRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-processing {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  padding: 3px;

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__item {
    position: absolute;
    width: 2px;
    height: 5px;
    background-color: $white;
    transition: opacity 0.15s linear;

    &:nth-child(1) {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;
    }

    &:nth-child(2) {
      top: 2px;
      right: 2px;
      transform: rotate(45deg) translate(-50%);
      opacity: 0.3;
    }

    &:nth-child(3) {
      top: 50%;
      right: 0;
      width: 5px;
      height: 2px;
      transform: translateY(-50%);
      opacity: 0.4;
    }

    &:nth-child(4) {
      right: 2px;
      bottom: 2px;
      transform: rotate(315deg) translate(-50%);
      opacity: 0.5;
    }

    &:nth-child(5) {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0.6;
    }

    &:nth-child(6) {
      bottom: 2px;
      left: 2px;
      transform: rotate(45deg) translate(50%);
      opacity: 0.7;
    }

    &:nth-child(7) {
      top: 50%;
      width: 5px;
      height: 2px;
      transform: translateY(-50%);
      opacity: 0.8;
    }

    &:nth-child(8) {
      top: 2px;
      left: 2px;
      transform: rotate(315deg) translate(50%);
      opacity: 0.9;
    }
  }
}
