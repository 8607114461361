/*--------------------
  TITLE
--------------------*/
@import "src/assets/styles/helpers/utils";

.h1 {
  @include font(24px, 32px, 500);

  @include media($tabletL) {
    font-size: 20px;
    line-height: 26px;
  }
}

.h2 {
  @include font(18px, 24px, 500);

  @include media($tabletL) {
    font-size: 16px;
    line-height: 22px;
  }
}

