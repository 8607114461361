/*--------------------
  BALANCE
--------------------*/
@import "src/assets/styles/helpers/utils";

.balance {
  width: 270px;
  min-width: 270px;
  padding: 24px;
  background-color: $neutrals5;
  border-radius: 16px;

  @include media($tabletL) {
    position: static;
    padding: 24px 15px;
  }

  @include media($mobileL) {
    width: auto;
    min-width: 100%;
  }

  &__list {
    &-item {
      &:not(:last-child) {
        margin-bottom: 24px;

        @include media($tabletL) {
          margin-bottom: 16px;
        }
      }
    }
  }

  &__buttons {
    > * {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    > * {
      &:not(:last-child) {
        margin-bottom: 24px;

        @include media($tabletL) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
