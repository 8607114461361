/*--------------------
  BALANCE
--------------------*/
@import "src/assets/styles/helpers/utils";

.cooperation {
  min-width: 270px;
  padding: 24px;
  background-color: $neutrals5;
  border-radius: 16px;

  @include media($tabletL) {
    position: static;
    padding: 24px 15px;
  }

  @include media($mobileL) {
    width: auto;
    min-width: 100%;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 16px;
  }

  &__links {
    font-size: 15px;
    line-height: 32px;
  }
}
