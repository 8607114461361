/*--------------------
  COMMON STYLES
--------------------*/
@import "src/assets/styles/helpers/utils";

*,
*::after,
*::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: initial;
}

p {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

blockquote,
figure {
  margin: 0;
}

cite {
  font-style: normal;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: vertical;
}

input,
select,
textarea,
button {
  padding: 0;
  font: inherit;
  background-clip: padding-box;
  border: 1px solid #666666;
  border-radius: 0;
  outline: none;
  appearance: none;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

html,
body {
  @include font($fz-base, $lh-base, $fw-base);
  font-family: $ff-base;
  color: $white;
  background-color: $neutrals6;
  scroll-behavior: smooth;
}

.link {
  color: $secondary1;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.25s;

  &:hover {
    color: $white;
  }
}

.visible-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.overflow-hidden {
  overflow: hidden;
}

.selection-rect {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 205, 86, 0.1);
  border: 1px dashed #ffcd56;
  border-radius: 10px;
  //border: 1px dashed #ba62ff;
  //background: rgba(186, 98, 255, 0.1);
  //border: 1px dashed #fff;
  //background: rgba(255, 255, 255, 0.15);
  pointer-events: none;
}

.document {
  color: $neutrals2;

  h2 {
    margin: 10px 33px 20px 0;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: $neutrals1;
  }

  h3 {
    margin: 40px 0 20px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: $neutrals1;
  }

  h4 {
    margin: 30px 0 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $neutrals1;
  }

  p {
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 20px;
    padding-left: 30px;
    list-style: disc;
  }

  li {
    margin-bottom: 0.5em;
    list-style: disc;
  }
}
