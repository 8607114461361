/*--------------------
  LOGIN
--------------------*/
@import "../helpers/utils";

.page-login {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5em;
  font-family: "Rubik", sans-serif;
  //background: radial-gradient(30vw at 100% 0%, $primary1, #6d4096 30%, #4c2d69 50%, #201832 70%, #16181f);

  &__background {
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    &::before {
      @include decor(0 0 auto auto, (50%, -50%), 30vw, 30vw, $primary1, 10vw);
      content: "";

      @include media($tabletL) {
        @include size(250px);
        filter: blur(100px);
      }
    }

    &::after {
      @include decor(auto auto 0 0, (-50%, 50%), 30vw, 30vw, $primary1, 10vw);
      content: "";

      @include media($tabletL) {
        @include size(250px);
        filter: blur(100px);
      }
    }
  }

  @include media($laptopL) {
    flex-wrap: wrap;
  }

  @include media($tabletL) {
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    //padding: 20px 24px;
    font-size: 18px;
  }
}

.section {
  width: 33%;
  padding: 0 50px;

  @include media($laptopXL) {
    padding: 20px 30px;
  }

  @include media($laptopL) {
    width: 50%;
  }

  @include media($tabletL) {
    padding: 20px 20px;
  }

  @include media($tabletL) {
    width: 100%;
    padding: 20px 0;
  }
}


.section-summary {
  max-width: 540px;

  h1 {
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 36px;
    line-height: 1.2em;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    padding-left: 30px;
  }

  li {
    margin-bottom: 20px;
    padding-left: 10px;
    list-style: disc;
  }

  @include media($laptopL) {
    width: 100%;
    max-width: 1000px;
  }

  @include media($tabletL) {
    h1 {
      margin-bottom: 30px;
    }

    li {
      margin-bottom: 10px;
    }
  }
}

.section-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 540px;
  text-align: center;

  p {
    margin: 20px 30px;
  }

  @include media($laptopM) {
    max-width: 450px;

    p {
      margin: 20px 10px;
    }
  }

  @include media($tabletL) {
    p {
      margin: 20px 30px;
    }
  }

  @include media($mobileL) {
    p {
      margin: 20px 10px;
    }
  }
}

.login-logo {
  max-width: 250px;
}

.login-nft-staking {
  max-width: 300px;
}

.form-login {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 40px;
  background-color: $neutrals5;
  border: 1px solid $neutrals4;
  border-radius: 16px;

  &__title {
    margin-bottom: 32px;
    text-align: center;
  }

  @include media($tabletL) {
    padding: 24px 16px;
  }
}

.section-video {
  max-width: 540px;
  text-align: center;
  //width: 640px;
  //background: radial-gradient(circle, #9330b1, #b647cf 34%, transparent 69%);
  //height: 600px;
  //display: flex;
  //justify-content: center;
  //align-items: center;

  //@include media($laptopM) {
  //  max-width: 450px;
  //}
}

.login-video {
  width: 100%;
  border-radius: 20px;

  @include media($laptopL) {
    max-width: 400px;
  }
}
