/*--------------------
  SEARCH
--------------------*/
@import "../helpers/utils";

.search {
  position: relative;
  z-index: 1;
  display: block;

  &__label {
    position: relative;
    display: block;
  }

  &__input {
    padding: 8px 12px 8px 44px;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);

    path {
      stroke: $neutrals2;
    }
  }
}
