/*--------------------
  MODAL
--------------------*/
@import "../helpers/utils";

.modal {
  position: fixed;
  inset: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: rgba($neutrals6, 0.7);

  &__icon {
    margin-right: 16px;

    @include media($tabletL) {
      margin-right: 12px;
    }
  }

  &__header {
    margin-bottom: 24px;

    @include media($tabletL) {
      margin-bottom: 16px;
    }
  }

  &__footer {
    margin-top: 24px;

    @include media($tabletL) {
      margin-top: 16px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;

    > * {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  &__wrapper {
    width: 100%;
    max-width: 440px;
    padding: 24px;
    background-color: $neutrals5;
    border: 1px solid $neutrals4;
    border-radius: 16px;

    @include media($tabletL) {
      padding: 16px;
    }

    &--small {
      max-width: 400px;
    }
  }

  &__text-info {
    font-size: 12px;
    line-height: 16px;
    color: $neutrals2;

    &:first-of-type {
      margin-top: 8px;
    }
  }

  &__inner {
    display: flex;
    align-items: flex-start;

    &--flex-item {
      margin-left: -8px;

      > * {
        margin-left: 8px;
      }
    }
  }

  &__btn-close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 25px;
    height: 25px;
    font-size: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;


    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      background-color: $neutrals3;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: background-color 0.15s linear;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover,
    &:focus {
      &::before,
      &::after {
        background-color: $white;
      }
    }
  }

  &__alert {
    position: relative;

    @include media($tabletL) {
      padding: 24px 16px;
    }

    &-link {
      margin: 16px 0 0;
    }

    &-title {
      margin-bottom: 2px;

      &--error {
        color: $secondary41;
      }

      &--success {
        color: $secondary11;
      }
    }
  }
}
