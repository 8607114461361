/*--------------------
  INPUT
--------------------*/
@import "src/assets/styles/helpers/utils";

.input {
  width: 100%;
  height: 40px;
  padding: 8px 12px 8px 12px;
  color: $white;
  background-color: $neutrals4;
  border: 1px solid $neutrals4;
  border-radius: 8px;
  transition: border-color 0.15s linear;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;

    &:hover,
    &:focus {
      border-color: $neutrals4;
    }
  }

  &:hover,
  &:focus {
    border-color: $primary1;
  }

  &::placeholder {
    color: $neutrals2;
  }
}

.checkbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: $neutrals5;
  border: 2px solid $neutrals4;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.15s linear, border-color 0.15s linear;

  &:disabled {
    pointer-events: none;
  }

  &__indicator {
    position: absolute;
    top: calc(50% - 1px);
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.15s linear;

    &.checked {
      opacity: 1;
    }
  }

  &.checked {
    background-color: $primary1;
    border-color: $primary1;
  }

  input[type="checkbox"] {
    display: none;
  }
}
