/*--------------------
  PANEL
--------------------*/
@import "../helpers/utils";

.panel {
  display: flex;

  @include media($tabletL) {
    flex-direction: column;
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 100px 24px 24px 124px;

    @include media($tabletM) {
      padding: 76px 15px 24px;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    height: 100%;
    margin-bottom: 24px;

    @include media($tabletL) {
      flex-direction: column-reverse;
      margin-bottom: 40px;
    }
  }

  &__balance {
    position: sticky;
    top: 100px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 270px;
    min-width: 270px;

    @include media($tabletL) {
      flex-direction: row;
      width: 100%;
      min-width: 100%;
    }

    @include media($tabletM) {
      position: static;
      flex-direction: column;
    }
  }

  &__information {
    flex-grow: 1;
    height: 100%;
    min-height: calc(100vh - 170px);
    padding: 24px;
    background-color: $neutrals5;
    border-radius: 16px;

    @include media($tabletL) {
      width: 100%;
      padding: 24px 15px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    color: $neutrals3;
  }

  &__copyright {
    @include media($tabletL) {
      text-align: center;
    }
  }
}
